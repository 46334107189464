/* eslint-disable */
import { createApp } from "vue";

import router from "./router";

//Vue Componenets
import App from "./App.vue";

//--> global and UI components
import SuccessMessage from "./components/UI/SuccessMessage.vue";
import BaseButton from "./components/UI/BaseButton.vue";
import BaseInput from "./components/UI/BaseInput.vue";
import TooltipWrapper from "./components/UI/TooltipWrapper.vue";

//Primevue, Bootstrap and other components
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Row from "primevue/row";
import Tooltip from "primevue/tooltip";

//Primevue and Bootstrap css
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "tailwindcss/tailwind.css"; //tailwind

// Axios
// import axios from "axios";
// import VueAxios from "vue-axios";

const app = createApp(App);

app.component("base-button", BaseButton);
app.component("success-message", SuccessMessage);
app.component("base-input", BaseInput);
app.component("tooltip-wrapper", TooltipWrapper);

//primevue components
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Row", Row);

app.directive("tooltip", Tooltip);
app.use(PrimeVue);
app.use(router);

app.mount("#app");
