<template>
  <tooltip-wrapper>
    <template #content>
      <svg
        style="cursor: pointer"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 4.70003V7.55C18 8.10228 17.5523 8.55 17 8.55H14.15C13.5977 8.55 13.15 8.10228 13.15 7.55C13.15 6.99772 13.5977 6.55 14.15 6.55H14.9141C13.8099 4.96734 12.0002 4 10 4C7.51128 4 5.31725 5.49728 4.41044 7.81447C4.20913 8.32878 3.629 8.5825 3.11475 8.38125C2.60044 8.17997 2.34669 7.59987 2.54797 7.08559C3.12794 5.60362 4.12756 4.33853 5.43878 3.42706C6.78181 2.49347 8.35903 2 10 2C11.641 2 13.2182 2.49347 14.5612 3.42703C15.0948 3.79797 15.5769 4.22753 16 4.70641V4.70006C16 4.14778 16.4477 3.70006 17 3.70006C17.5523 3.70006 18 4.14775 18 4.70003ZM16.8853 11.6188C16.371 11.4175 15.7909 11.6713 15.5896 12.1856C14.6827 14.5027 12.4887 16 10 16C8.04228 16 6.26706 15.0733 5.15719 13.55H5.84997C6.40225 13.55 6.84997 13.1023 6.84997 12.55C6.84997 11.9977 6.40225 11.55 5.84997 11.55H3C2.44772 11.55 2 11.9977 2 12.55V15.4C2 15.9523 2.44772 16.4 3 16.4C3.55228 16.4 4 15.9523 4 15.4V15.2936C4.42313 15.7725 4.90516 16.202 5.43878 16.573C6.78181 17.5065 8.35903 18 10 18C11.641 18 13.2182 17.5065 14.5612 16.573C15.8724 15.6615 16.8721 14.3964 17.452 12.9144C17.6533 12.4002 17.3996 11.8201 16.8853 11.6188Z"
          fill="#575959"
        />
      </svg>
    </template>
    Refresh product errors
  </tooltip-wrapper>
</template>
