<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.600098" width="40" height="40" rx="8" fill="#F8D847" />
    <path
      d="M36.6016 32.6623C36.0711 33.5738 35.1194 34.1181 34.0572 34.1181H7.94278C6.88053 34.1181 5.92947 33.5738 5.39834 32.6623C4.86722 31.7508 4.86722 30.6622 5.39834 29.7507L18.4555 7.33861C18.9867 6.4271 19.9377 5.88281 21 5.88281C22.0622 5.88281 23.0133 6.4271 23.5444 7.33861L36.6016 29.7507C37.1328 30.6622 37.1328 31.7508 36.6016 32.6623Z"
      fill="white"
    />
    <path
      d="M22.7779 14.6347V20.8102C22.7779 22.1213 21.781 23.1876 20.5557 23.1876C19.3304 23.1876 18.3335 22.1213 18.3335 20.8102V14.6347C18.3335 13.3241 19.3304 12.2578 20.5557 12.2578C21.781 12.2578 22.7779 13.3241 22.7779 14.6347Z"
      fill="#F8D847"
    />
    <path
      d="M22.7779 29.1071C22.7779 30.3626 21.781 31.3842 20.5557 31.3842C19.3304 31.3842 18.3335 30.3626 18.3335 29.1071C18.3335 27.8516 19.3304 26.8301 20.5557 26.8301C21.781 26.8301 22.7779 27.8516 22.7779 29.1071Z"
      fill="#F8D847"
    />
  </svg>
</template>
