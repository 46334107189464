<template>
  <nav>
    <!-- 
      This nav buttons should request data from backend when click
     -->
    <ul>
      <li v-for="(tab, index) in navTabs" :key="tab.id">
        <button
          @click="setCurrentTab(tab.tabComponent, index)"
          :class="currentTab === tab.tabComponent ? 'active' : ''"
        >
          <span>
            {{ tab.tabName }}
          </span>
          <span
            class="count"
            :class="{
              all: tab.tabName === 'All Products',
              errors: tab.tabName === 'With Error',
              pending: tab.tabName === 'Pending',
              submitted: tab.tabName === 'Submitted',
              warnings: tab.tabName === 'With Warnings',
              excluded: tab.tabName === 'Excluded',
            }"
            >{{ tab.dataCount }}</span
          >
        </button>
      </li>
    </ul>
    <hr class="nav-hr" />
  </nav>

  <div class="tab-content">
    <SearchBar />
    <CustomTable :productsData="navTabs[currentTabIndex].data" />
  </div>
</template>

<script>
import CustomTable from "../components/ProductsTablesComponents/CustomTable.vue";
import SearchBar from "../components/GeneralComponents/SearchBar.vue";

export default {
  mounted() {
    this.$emit("getTitle", "Products");
  },
  emits: ["getTitle"],
  components: { CustomTable, SearchBar },
  methods: {
    setCurrentTab(tab, index) {
      this.currentTab = tab;
      this.currentTabIndex = index;
    },
  },
  data() {
    return {
      currentTabIndex: 0,
      currentTab: "all-products",
      navTabs: [
        {
          id: 0,
          tabName: "All Products",
          tabComponent: "all-products",
          dataCount: 304,
          data: [
            {
              id: 0,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScgsJTy2dl-Tr4m1amid5OZyNTg-c2KlgbmnjO947w&s",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd123",
              product_description: "T-Shirt - M / Black",
              google_status: "submitted",
              google_status_details: [],
              updated_at: "1276068123",
              variant_id: "1234567",
            },
            {
              id: 1,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3DKnt9SGSLBGDk0bKff3VAweOhtpFsjj6yg&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "with_errors",
              google_status_details: [
                "Invalid text value[null]",
                "[Id] validation/missing_required: Invalid or missing required attribute: Id",
              ],
              updated_at: "1271238608",
              variant_id: "12345678",
            },
            {
              id: 2,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd456",
              product_description: "T-Shirt - M / Black",
              google_status: "with_warnings",
              google_status_details: [
                "Missing value [color]",
                "Missing value [age group]",
              ],
              updated_at: "1276061238",
              variant_id: "123456789",
            },
            {
              id: 3,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScgsJTy2dl-Tr4m1amid5OZyNTg-c2KlgbmnjO947w&s",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd789",
              product_description: "T-Shirt - M / Black",
              google_status: "submitted",
              google_status_details: [],
              updated_at: "1245668608",
              variant_id: "1234567891",
            },
            {
              id: 4,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3DKnt9SGSLBGDk0bKff3VAweOhtpFsjj6yg&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123456",
              product_description: "T-Shirt - M / Black",
              google_status: "with_errors",
              google_status_details: [
                "Invalid text value[null]",
                "[Id] validation/missing_required: Invalid or missing required attribute: Id",
              ],
              updated_at: "1277898608",
              variant_id: "12345678910",
            },
            {
              id: 5,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123789",
              product_description: "T-Shirt - M / Black",
              google_status: "with_warnings",
              google_status_details: [
                "Missing value [color]",
                "Missing value [age group]",
              ],
              updated_at: "1276068608",
              variant_id: "123456789101",
            },
            {
              id: 6,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScgsJTy2dl-Tr4m1amid5OZyNTg-c2KlgbmnjO947w&s",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "asd123",
              product_description: "T-Shirt - M / Black",
              google_status: "submitted",
              google_status_details: [],
              updated_at: "1276068608",
              variant_id: "1234567891011",
            },
            {
              id: 7,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3DKnt9SGSLBGDk0bKff3VAweOhtpFsjj6yg&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "asd456",
              product_description: "T-Shirt - M / Black",
              google_status: "with_errors",
              google_status_details: [
                "Invalid text value[null]",
                "[Id] validation/missing_required: Invalid or missing required attribute: Id",
              ],
              updated_at: "1276068608",
              variant_id: "12345678910111",
            },
            {
              id: 8,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "asd789",
              product_description: "T-Shirt - M / Black",
              google_status: "with_warnings",
              google_status_details: [
                "Missing value [color]",
                "Missing value [age group]",
              ],
              updated_at: "1276068608",
              variant_id: "123456789101112",
            },
          ],
        },
        {
          id: 1,
          tabName: "With Error",
          tabComponent: "products-with-errors",
          dataCount: 23,
          data: [
            {
              id: 0,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "with_errors",
              google_status_details: [
                "Invalid text value[null]",
                "[Id] validation/missing_required: Invalid or missing required attribute: Id",
              ],
              updated_at: "1276068608",
            },
            {
              id: 1,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "with_errors",
              google_status_details: [
                "Invalid text value[null]",
                "[Id] validation/missing_required: Invalid or missing required attribute: Id",
              ],
              updated_at: "1276068608",
            },
            {
              id: 2,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "with_errors",
              google_status_details: [
                "Invalid text value[null]",
                "[Id] validation/missing_required: Invalid or missing required attribute: Id",
              ],
              updated_at: "1276068608",
            },
          ],
        },
        {
          id: 2,
          tabName: "Pending",
          tabComponent: "pending-products",
          dataCount: 0,
          data: [],
        },
        {
          id: 3,
          tabName: "Submitted",
          tabComponent: "submitted-products",
          dataCount: 126,
          data: [
            {
              id: 0,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScgsJTy2dl-Tr4m1amid5OZyNTg-c2KlgbmnjO947w&s",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "submitted",
              google_status_details: [],
              updated_at: "1276068608",
            },
            {
              id: 1,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScgsJTy2dl-Tr4m1amid5OZyNTg-c2KlgbmnjO947w&s",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "submitted",
              google_status_details: [],
              updated_at: "1276068608",
            },
            {
              id: 2,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScgsJTy2dl-Tr4m1amid5OZyNTg-c2KlgbmnjO947w&s",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "submitted",
              google_status_details: [],
              updated_at: "1276068608",
            },
          ],
        },
        {
          id: 4,
          tabName: "With Warnings",
          tabComponent: "products-with-warnings",
          dataCount: 3,
          data: [
            {
              id: 0,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "with_warnings",
              google_status_details: [
                "Missing value [color]",
                "Missing value [age group]",
              ],
              updated_at: "1276068608",
            },
            {
              id: 1,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "with_warnings",
              google_status_details: [
                "Missing value [color]",
                "Missing value [age group]",
              ],
              updated_at: "1276068608",
            },
            {
              id: 2,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "with_warnings",
              google_status_details: [
                "Missing value [color]",
                "Missing value [age group]",
              ],
              updated_at: "1276068608",
            },
            {
              id: 3,
              product_image:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF5IWEX-CX3AqMY-TGVrLh4RiDP8UtI4UMcEYtQQQExLoAMwC_OHZxZVzOwsPFSfXl1UM&usqp=CAU",
              product_name:
                'Belvedere "Gabriele" Antique Blue Jean Genuine Alligator...',
              product_id: "123asd",
              product_description: "T-Shirt - M / Black",
              google_status: "with_warnings",
              google_status_details: [
                "Missing value [color]",
                "Missing value [age group]",
              ],
              updated_at: "1276068608",
            },
          ],
        },
        {
          id: 5,
          tabName: "Excluded",
          tabComponent: "excluded-products",
          dataCount: 126,
          data: [],
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
nav {
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  padding-top: 22px;
  padding-right: 23px;
  height: 70px;
}

ul {
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 12px;
  height: 100%;
  list-style: none;
}

.nav-hr {
  border-bottom: 1px solid #cecece;
  margin-top: auto;
}

nav button {
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  gap: 8px;
  height: 93%;
  color: #6d6d6d;
  outline: none;
  z-index: 1;
}

.active {
  box-shadow: 0 4px 0 #1877f2;
  color: #202223;
}

span.count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 24px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 100px;
}
span.count.all {
  background: #1877f2;
}
span.count.errors {
  background: #c83e25;
}
span.count.pending {
  background: #004982;
}
span.count.submitted {
  background: #07d09e;
}
span.count.warnings {
  background: #ffc453;
}

span.count.excluded {
  background: #c83e25;
}
@media only screen and (max-width: 1200px) {
}
</style>
