<template>
  <div class="empty-page">
    <svg
      width="169"
      height="168"
      viewBox="0 0 169 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M144.875 37.6074H32C27.6504 37.6074 24.125 41.1328 24.125 45.4824V61.2324H144.875V37.6074Z"
        fill="#E0EAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M144.875 33.1797C144.875 28.8301 141.35 25.3047 137 25.3047C125.899 25.3047 105.886 25.3047 95.8426 25.3047C92.0757 25.3047 88.595 27.3233 86.726 30.5941C83.6259 36.0226 79.25 43.6797 79.25 43.6797H144.875V33.1797Z"
        fill="#E0EAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.125 44.9863H144.875V49.8767H24.125V44.9863Z"
        fill="#CECECE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M153.716 60.1417C154.134 57.1334 153.228 54.0911 151.236 51.8021C149.241 49.5104 146.353 48.1953 143.316 48.1953C116.552 48.1953 52.449 48.1953 25.6845 48.1953C22.6474 48.1953 19.7599 49.5104 17.7649 51.8021C15.7725 54.0911 14.8669 57.1334 15.2843 60.1417C17.946 79.3042 23.112 116.495 25.4929 133.642C26.2148 138.831 30.651 142.695 35.8931 142.695H133.107C138.35 142.695 142.786 138.831 143.508 133.642C145.889 116.495 151.055 79.3042 153.716 60.1417Z"
        fill="#E0EAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1663 66.4942C16.3631 64.3076 17.2399 62.2181 18.7073 60.5328C20.6996 58.2438 23.5871 56.9287 26.6243 56.9287H142.376C145.413 56.9287 148.301 58.2438 150.293 60.5328C151.761 62.2181 152.637 64.3076 152.834 66.4942L153.716 60.1417C154.134 57.1334 153.228 54.0911 151.236 51.8021C149.241 49.5104 146.353 48.1953 143.316 48.1953C116.552 48.1953 52.449 48.1953 25.6845 48.1953C22.6474 48.1953 19.7599 49.5104 17.7649 51.8021C15.7725 54.0911 14.8669 57.1334 15.2843 60.1417L16.1663 66.4942Z"
        fill="#9FD5FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.6904 116.514C72.6904 113.616 70.3411 111.264 67.4404 111.264C61.6156 111.264 52.2679 111.264 46.4404 111.264C43.5424 111.264 41.1904 113.616 41.1904 116.514V121.764C41.1904 124.664 43.5424 127.014 46.4404 127.014H67.4404C70.3411 127.014 72.6904 124.664 72.6904 121.764C72.6904 120.076 72.6904 118.204 72.6904 116.514Z"
        fill="#9FD5FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123.036 84C112.894 84 104.66 92.2341 104.66 102.376C104.66 112.517 112.894 120.751 123.036 120.751C133.178 120.751 141.412 112.517 141.412 102.376C141.412 92.2341 133.178 84 123.036 84ZM123.036 89.2502C130.279 89.2502 136.161 95.1322 136.161 102.376C136.161 109.619 130.279 115.501 123.036 115.501C115.792 115.501 109.91 109.619 109.91 102.376C109.91 95.1322 115.792 89.2502 123.036 89.2502Z"
        fill="#1575BF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M132.608 115.066L148.932 131.39C149.958 132.416 151.62 132.416 152.646 131.39C153.67 130.367 153.67 128.702 152.646 127.678L136.321 111.354C135.296 110.33 133.633 110.33 132.608 111.354C131.584 112.378 131.584 114.042 132.608 115.066Z"
        fill="#1575BF"
      />
    </svg>

    <h5>No prducts with warnings</h5>
    <p>All Products will appear here.</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.empty-page {
  min-height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 186px;

  align-items: center;
}

.mail-svg {
  margin-bottom: 20px;
}

.empty-page h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 5px;
}

.empty-page p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
  color: #6d6d6d;
}
</style>
