export default {
  formatTimeStamp(dateUnixTimeStamp) {
    function addZeroToTheLeft(text) {
      return text.length === 2 ? text : "0" + text;
    }
    // format date unix to "yyyy-mm-dd hh-mm-ss"
    const date = new Date(dateUnixTimeStamp * 1000);
    const seconds = addZeroToTheLeft(String(date.getSeconds()));
    const minutes = addZeroToTheLeft(String(date.getMinutes()));
    const hours = addZeroToTheLeft(String(date.getHours()));
    const day = addZeroToTheLeft(String(date.getDate()));
    const month = addZeroToTheLeft(String(date.getMonth() + 1));
    const year = String(date.getFullYear());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  },
};
