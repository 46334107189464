import { createRouter, createWebHistory } from "vue-router";

import StatisticsCards from "@/views/StatisticsCards.vue";
import ProductsTables from "@/views/ProductsTables.vue";
import SettingsTab from "@/views/SettingsTab.vue";

const routes = [
  { path: "/", redirect: "/facebook-feed" },
  { path: "/stats", redirect: "/facebook-feed" },
  { path: "/facebook-feed", component: StatisticsCards },
  { path: "/products", component: ProductsTables },
  { path: "/settings", component: SettingsTab },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active-tab",
});

export default router;
