<template>
  <svg
    clip-rule="evenodd"
    fill-rule="evenodd"
    height="2500"
    viewBox="-.83 -.04 65.2 69.73"
    width="2271"
    fill="none"
    image-rendering="optimizeQuality"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#3a589e">
      <path
        d="m15.97 23.88c.57 1.08.56 2.79 2.6 4.44 4.38 3.54 10.01 1.89 12.04-1.87.27-.5.93-2.38 1.08-2.58.6 1.04.57 2.57 2.63 4.44 4.2 3.79 11.56 2.12 13.06-4.46l.41.88c.51 1.66 1.26 2.72 2.63 3.87 5.18 4.35 13.95-.08 12.77-7.53-.15-.96-2.08-6.61-2.51-8.01-.79-2.56-1.76-5.35-2.52-7.92-.8-2.66-1.46-5.07-5.45-5.11-6.99-.07-14.02 0-21.02 0s-14.01-.03-21.02 0c-1.48.01-2.98.56-3.78 1.36-.98 1-1.21 2.22-1.65 3.62-.83 2.7-1.68 5.32-2.55 8.02-.46 1.41-2.3 6.91-2.48 7.99-1.04 6.42 5.42 11.06 10.96 8.63 2.04-.89 3.77-2.74 4.4-4.82.17-.58.1-.68.4-.95zm-7.59 9.14-2.52-.08-.03 16.97c-.02 4.51 3.12 7.66 7.56 7.67 11.96 0 24.89-.1 37.01.01 3.44.03 7.12-2.99 7.12-7.14l.01-17.52c-.57-.06-2.14.11-2.93.09-1.26-.03-1.83-.43-2.89-.51l-.01 16.99c0 2.6-.98 2.1-3.2 2.1-10.44 0-20.88-.05-31.31.09l-3.53-.02c-2.46 0-2.03-1.2-2.04-4.34l.03-14.8c-1.01-.01-1.82.49-3.27.49z"
      />
      <path
        d="m7.33 69.56h.76l-.26-8.62h-1.11l-2.67 7.04-2.68-7.04h-1.11l-.26 8.62h.76l.19-7.86 2.51 6.75h1.18l2.51-6.75zm7.17-4.61c0-1.17-.65-1.82-2.28-1.82-.64 0-1.43.1-1.94.24l.12.64c.58-.12 1.24-.21 1.86-.21 1.04 0 1.49.29 1.49 1.15v1.23h-1.86c-1.25 0-1.86.42-1.86 1.75 0 1.13.52 1.76 1.64 1.76.83 0 1.52-.24 2.13-.62l.05.49h.65zm-.75 3.42c-.57.4-1.31.65-1.93.65-.88 0-1.03-.34-1.03-1.08 0-.82.25-1.08 1.12-1.08h1.84zm5.48-5.24c-.6.22-1.27.65-1.94 1.09l-.04-.96h-.68v6.3h.75v-4.53c.61-.4 1.34-.88 2-1.16zm2.13 6.43v-8.98l-.75.1v8.88zm2.83-6.3-2.49 3.11 2.57 3.19h.95l-2.61-3.19 2.51-3.11zm2.85 4.17v-.79h3.89v-1.22c0-1.29-.51-2.29-2.29-2.29-1.77 0-2.35 1-2.35 2.29v1.98c0 1.37.57 2.29 2.34 2.29.76 0 1.59-.14 2.17-.35l-.13-.61c-.62.17-1.34.3-1.99.3-1.26 0-1.64-.48-1.64-1.6zm0-2.04c0-1.06.42-1.61 1.6-1.61s1.56.55 1.56 1.61v.57h-3.16zm8.88 3.47c-.38.12-.78.19-1.13.19-.64 0-.72-.24-.72-.79v-4.35h1.85l.08-.65h-1.93v-1.6l-.75.11v1.49h-1.22v.65h1.22v4.42c0 1 .42 1.36 1.42 1.36.45 0 .96-.1 1.32-.24zm1.76.7h.76v-3.29h2.17c1.73 0 2.37-.79 2.37-1.99v-1.35c0-1.2-.64-1.99-2.37-1.99h-2.93zm4.53-6.63v1.35c0 .95-.5 1.31-1.59 1.31h-2.18v-3.96h2.18c1.09 0 1.59.36 1.59 1.3zm3.2 6.63v-8.98l-.75.1v8.88zm6.41-4.61c0-1.17-.65-1.82-2.28-1.82-.65 0-1.44.1-1.94.24l.11.64a9.65 9.65 0 0 1 1.87-.21c1.04 0 1.49.29 1.49 1.15v1.23h-1.87c-1.24 0-1.85.42-1.85 1.75 0 1.13.52 1.76 1.63 1.76.83 0 1.53-.24 2.14-.62l.05.49h.65zm-.75 3.42c-.57.4-1.31.65-1.93.65-.88 0-1.04-.34-1.04-1.08 0-.82.26-1.08 1.13-1.08h1.84zm3.18-3.09c0-1.13.46-1.48 1.64-1.48.37 0 .81.04 1.34.13l.09-.62a5.96 5.96 0 0 0 -1.43-.18c-1.78 0-2.39.81-2.39 2.17v2.22c0 1.36.61 2.17 2.39 2.17.42 0 .99-.06 1.43-.18l-.09-.62c-.53.09-.97.13-1.34.13-1.18 0-1.64-.35-1.64-1.48zm5.21 2.15v-.79h3.9v-1.22c0-1.29-.52-2.29-2.3-2.29-1.77 0-2.35 1-2.35 2.29v1.98c0 1.37.57 2.29 2.34 2.29.77 0 1.59-.14 2.18-.35l-.13-.61c-.62.17-1.35.3-2 .3-1.25 0-1.64-.48-1.64-1.6zm0-2.04c0-1.06.43-1.61 1.6-1.61 1.18 0 1.56.55 1.56 1.61v.57h-3.16z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 28px;
  height: 28px;
}
</style>
