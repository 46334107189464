<template>
  <!-- Lexmodo Authentication -->
  <div v-if="statusApp">
    <div>
      <UpperBar title="Lexmodo Market">
        <PluginLogoSvg></PluginLogoSvg>
      </UpperBar>
      <nav-bar></nav-bar>
      <title-bar :title="currentTitle"></title-bar>
      <div class="menu-tab">
        <router-view @get-title="handleTabTitle"></router-view>
      </div>
    </div>
  </div>
  <div v-else class="app-loading">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</template>

<script setup>
import TitleBar from "./components/GeneralComponents/TitleBar/TitleBar.vue";
import NavBar from "./components/GeneralComponents/Navbar/NavBar.vue";
import UpperBar from "./components/UI/UpperBar/UpperBar.vue";
import PluginLogoSvg from "./components/UI/UpperBar/PluginLogoSvg.vue";

import { useRouter } from "vue-router";
import { authLogin } from "./services/authLogin";
import { ref } from "vue";

//authentication
const statusApp = ref(false),
  auth = new authLogin(),
  router = useRouter(),
  widgetData = ref({}),
  productWidget = ref(false);

auth.startApp()?.then((data) => {
  console.log("auth_token: " + data.meta.token);
  if (!data.meta.redirect) {
    if (data.product_widget?.product_widget === true) {
      widgetData.value = data;
      statusApp.value = true;
      productWidget.value = true;
      router.push("/widgets");
    } else {
      statusApp.value = true;
      router.push("/facebook-feed");
    }
  }
});

const currentTitle = ref("");

const handleTabTitle = (tabTitle) => {
  currentTitle.value = tabTitle;
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  padding: 44px;
  min-height: 100vh;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background-color: #f2f5fa;
  scrollbar-gutter: "stable";
}

.menu-tab {
  background: #ffffff;
  border: 1px solid #edeeef;
  border-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;

  position: relative;
}

/* Hide number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 1200px) {
  #app {
    padding: 40px;
  }
}
@media only screen and (max-width: 1000px) {
  #app {
    padding: 35px;
  }
}
@media only screen and (max-width: 800px) {
  #app {
    padding: 30px;
  }
}
@media only screen and (max-width: 600px) {
  #app {
    padding: 25px;
  }
}
@media only screen and (max-width: 400px) {
  #app {
    padding: 20px;
  }
}
@media only screen and (max-width: 350px) {
  #app {
    padding: 15px;
  }
}
</style>
