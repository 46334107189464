<template>
  <p>settings tab content</p>
</template>

<script>
export default {
  mounted() {
    this.$emit("getTitle", "Settings");
  },
  emits: ["getTitle"],
};
</script>
