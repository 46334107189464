<template>
  <button>
    <slot>Please Enter Button Label</slot>
  </button>
</template>

<style scoped>
button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  height: 45px;
  padding: 0 15px;
  background-color: #1877f2;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  outline: none;
  transition: all 0.3s;
}

button:hover,
button:active {
  background-color: #1166a8;
}

button:disabled {
  cursor: auto;
  background-color: #acacac;
}

.small {
  height: 40px;
}

.flat {
  color: #1877f2;
  background-color: #fff;
  border: 1px solid #cecece;
}

button.flat:hover,
button.flat:active {
  background-color: #f6f6f6;
}

.red-text {
  color: #c83e25;
}

.search-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 50px;
}
</style>
