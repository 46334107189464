<template>
  <div class="title-bar">
    <p class="title-bar-left">{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style scoped>
.title-bar {
  display: flex;
  justify-content: space-between;
  padding: 17px 0 14px 0;
}

.title-bar-left {
  display: flex;
  align-items: center;
  color: #202223;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

@media only screen and (max-width: 1100px) {
  .title-bar {
    align-items: start;
  }
}
</style>
