<template>
  <div class="container-search-bar">
    <div class="cotnainer-inputs">
      <base-input
        type="text"
        placeholder="Product Name"
        @input="handleName"
        :value="product_name"
      ></base-input>
      <base-input
        type="text"
        placeholder="Product ID"
        @input="handleProductId"
        :value="product_id"
      ></base-input>
      <base-input
        type="text"
        placeholder="Variant ID"
        @input="handleVariantId"
        :value="variant_id"
      ></base-input>
    </div>
    <base-button @click="searchProducts">
      <span class="search-text"> Search in merchant center </span>
      <span class="search-icon">
        <i class="pi pi-search"></i>
      </span>
    </base-button>
  </div>
  <hr />
</template>

<script>
export default {
  data() {
    return {
      product_name: "",
      product_id: "",
      variant_id: "",
    };
  },
  methods: {
    handleName(event) {
      this.product_name = event.currentTarget.value;
    },
    handleProductId(event) {
      this.product_id = event.currentTarget.value;
    },
    handleVariantId(event) {
      this.variant_id = event.currentTarget.value;
    },
    searchProducts() {
      console.log("Search database with this");
      console.log("Name: " + this.product_name);
      console.log("Product id: " + this.product_id);
      console.log("Variant id: " + this.variant_id);
    },
  },
};
</script>

<style scoped lang="scss">
.container-search-bar {
  padding: 17px 28px;
  display: flex;
  width: 100%;
  gap: 9px;
  button {
    white-space: nowrap;
  }
}
.cotnainer-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 9px;
}
.search-icon {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .cotnainer-inputs {
    grid-template-columns: 1fr 1fr;
    row-gap: 9px;
  }
}
@media only screen and (max-width: 800px) {
  .cotnainer-inputs {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 600px) {
  .search-text {
    display: none;
  }
  .search-icon {
    display: flex;
  }
}
</style>
