<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.399902" width="40" height="40" rx="8" fill="#F89C47" />
    <g clip-path="url(#clip0_7565_384307)">
      <path
        d="M19.5 7C12.0444 7 6 13.0439 6 20.5C6 27.9561 12.0444 34 19.5 34C26.9556 34 33 27.9556 33 20.5C33 13.0444 26.9561 7 19.5 7Z"
        fill="white"
      />
      <path
        d="M26.666 20.5001C26.666 20.8967 26.5084 21.2771 26.228 21.5576C25.9475 21.8381 25.5671 21.9956 25.1705 21.9956H19.4999C19.3035 21.9957 19.109 21.9571 18.9275 21.8819C18.7461 21.8068 18.5812 21.6967 18.4423 21.5578C18.3034 21.4189 18.1932 21.254 18.1181 21.0725C18.043 20.891 18.0043 20.6965 18.0044 20.5001V11.7045C18.0044 11.3079 18.162 10.9275 18.4424 10.647C18.7229 10.3666 19.1033 10.209 19.4999 10.209C19.8966 10.209 20.277 10.3666 20.5575 10.647C20.8379 10.9275 20.9955 11.3079 20.9955 11.7045V19.0046H25.1705C25.5671 19.0046 25.9475 19.1621 26.228 19.4426C26.5084 19.7231 26.666 20.1035 26.666 20.5001Z"
        fill="#F89C47"
      />
    </g>
    <defs>
      <clipPath id="clip0_7565_384307">
        <rect width="27" height="27" fill="white" transform="translate(6 7)" />
      </clipPath>
    </defs>
  </svg>
</template>
