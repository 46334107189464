<template>
  <nav>
    <ul>
      <li v-for="item in navData" :key="item.tabRoute">
        <router-link :to="item.tabRoute">
          <span>
            <component :is="item.iconSvg"></component>
          </span>
          <span>{{ item.tabLabel }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import IconCountersSvg from "./Icons/IconCountersSvg.vue";
import IconProductsSvg from "./Icons/IconProductsSvg.vue";
import IconSettingsSvg from "./Icons/IconSettingsSvg.vue";

import { markRaw, ref } from "vue";

const navData = ref([
  {
    tabLabel: "Dashboard",
    tabRoute: "/facebook-feed",
    iconSvg: markRaw(IconCountersSvg),
  },
  {
    tabLabel: "Products",
    tabRoute: "/products",
    iconSvg: markRaw(IconProductsSvg),
  },
  {
    tabLabel: "Settings",
    tabRoute: "/settings",
    iconSvg: markRaw(IconSettingsSvg),
  },
]);
</script>

<style scoped>
nav {
  padding: 0 28px;
  width: 100%;
  height: 65px;
  background-color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6d6d6d;
}

ul {
  height: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 53px;
  border-bottom: 1px solid #cecece;
}

ul li {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

ul li:last-of-type {
  margin-left: auto;
}

ul li:hover {
  border-bottom: 3px solid #cecece;
}

.active-tab {
  border-bottom: 3px solid #1877f2;
  color: #202223;
  color: #1877f2;
}
a.active-tab :deep(svg) {
  fill: #202223;
  fill: #1877f2;
}
a.active-tab :deep(path) {
  fill: #202223;
  fill: #1877f2;
}

ul li span {
  margin-right: 10px;
}

li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #6d6d6d;
  height: 100%;
}

@media only screen and (max-width: 530px) {
  nav {
    height: auto;
    padding: 20px;
  }
  ul {
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    gap: 20px;
    border-bottom: none;
    flex-direction: column;
  }
  ul li:last-of-type {
    margin-left: 0;
  }
}
</style>
