<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" fill="#FFC453" />
    <path
      d="M13.5098 7.61539V11.8124C13.5098 12.7035 12.8323 13.4282 11.9995 13.4282C11.1668 13.4282 10.4893 12.7035 10.4893 11.8124V7.61539C10.4893 6.72469 11.1668 6 11.9995 6C12.8323 6 13.5098 6.72469 13.5098 7.61539Z"
      fill="white"
    />
    <path
      d="M13.5098 17.4518C13.5098 18.3051 12.8323 18.9994 11.9995 18.9994C11.1668 18.9994 10.4893 18.3051 10.4893 17.4518C10.4893 16.5985 11.1668 15.9043 11.9995 15.9043C12.8323 15.9043 13.5098 16.5985 13.5098 17.4518Z"
      fill="white"
    />
  </svg>
</template>
