<template>
  <div class="stats-main">
    <div class="title-bar">
      <p>View analytics for your store.</p>
      <div style="display: flex; flex-direction: column; gap: 5px">
        <label for="date-picker">Select from to dates:</label>
        <Calendar
          id="date-picker"
          @date-select="dateRangeSelect"
          @clear-click="removeFilter"
          v-model="date"
          dateFormat="dd/mm/yy"
          selectionMode="range"
          showIcon
          showButtonBar
          style="
            color: #202223;
            border: 1px solid #d8d8d8;
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
          "
        />
      </div>
    </div>
    <div class="cards-container">
      <stats-card
        v-for="card in cardsArray"
        :class="card.customClass"
        :cardCount="card.cardCount"
        :cardTitle="card.cardTitle"
        :key="card.cardTitle"
      >
        <component :is="card.svgComponent" />
      </stats-card>
    </div>
  </div>
</template>

<script>
import StatsCard from "@/components/StatisticsComponents/StatsCard.vue";
import Calendar from "primevue/calendar";

//This to solve key issue when rendering the components
import { markRaw } from "vue";

//Svg Icons import
import TotalProductsSvg from "@/components/StatisticsComponents/Icons/TotalProductsSvg.vue";
import TotalProductsErrorsSvg from "@/components/StatisticsComponents/Icons/TotalProductsErrorsSvg.vue";
import WarningsSvg from "@/components/StatisticsComponents/Icons/WarningsSvg.vue";
import PendingSvg from "@/components/StatisticsComponents/Icons/PendingSvg.vue";
import ExcludedSvg from "@/components/StatisticsComponents/Icons/ExcludedSvg.vue";

export default {
  mounted() {
    this.$emit("getTitle", "Dashboard");
  },
  emits: ["getTitle"],
  components: { StatsCard, Calendar },
  methods: {
    dateRangeSelect() {
      console.log(this.date);
    },
    removeFilter() {
      console.log(this.date);
    },
  },
  data() {
    return {
      date: null,
      cardsArray: [
        {
          cardCount: 123,
          cardTitle: "Total Prodcuts Uploaded",
          customClass: "total-products",
          svgComponent: markRaw(TotalProductsSvg),
        },
        {
          cardCount: 456,
          cardTitle: "Total Products Errors",
          customClass: "total-products-errors",
          svgComponent: markRaw(TotalProductsErrorsSvg),
        },
        {
          cardCount: 789,
          cardTitle: "With Warnings",
          customClass: "warnings",
          svgComponent: markRaw(WarningsSvg),
        },
        {
          cardCount: 111,
          cardTitle: "Pending",
          customClass: "pending",
          svgComponent: markRaw(PendingSvg),
        },
        {
          cardCount: 222,
          cardTitle: "Excluded",
          customClass: "excluded",
          svgComponent: markRaw(ExcludedSvg),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.stats-main {
  padding: 25px;
}
.title-bar {
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
}
.cards-container {
  justify-content: center;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 17px;
}

@media only screen and (max-width: 1650px) {
  .cards-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1400px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1050px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .cards-container {
    grid-template-columns: 1fr;
  }

  .title-bar {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
