<template>
  <input type="text" />
</template>

<style scoped lang="scss">
input {
  padding: 8px 15px 8px 20px;
  height: 45px;
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  outline: none;
}
input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}
</style>
