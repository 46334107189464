<template>
  <DataTable
    v-model:selection="selectedProducts"
    class="datatable"
    :value="productsData"
    :paginator="true"
    :rows="10"
    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    responsiveLayout="scroll"
    scrollable
    scroll-height="600px"
  >
    <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
    <Column
      field="product_name"
      header="Product Name"
      style="min-width: 400px"
      :filter="true"
    >
      <template #body="{ data }">
        <div class="product_container">
          <img
            class="product_image"
            :src="data.product_image"
            alt="product image"
          />
          <div>
            <p>{{ data.product_name }}</p>
            <a
              class="product_link"
              :href="`https://admin.lexmodo.com/products/${data.product_id}`"
              >{{ data.product_description }}</a
            >
          </div>
        </div>
      </template>
    </Column>
    <Column field="google" header="">
      <template #header>
        <tooltip-wrapper>
          <template #content>
            <MarketplaceSvg />
          </template>
          Free Listings on Facebook Marketplace
        </tooltip-wrapper>
      </template>
      <template #body="{ data }">
        <ErrorSvg v-if="data.google_status === 'with_errors'" />
        <WarningSvg v-else-if="data.google_status === 'with_warnings'" />
        <SubmittedSvg v-else-if="data.google_status === 'submitted'" />
        <p v-else>Check Svg</p>
      </template>
    </Column>

    <Column field="google_status_details" header="" style="min-width: 400px">
      <template #header>
        <div class="errors_header">
          <p>Errors from Merchant Center</p>
          <RefreshSvg @click="test" />
        </div>
      </template>
      <template #body="{ data }">
        <ul
          v-if="
            data.google_status === 'with_errors' ||
            data.google_status === 'with_warnings'
          "
        >
          <li v-for="(item, index) in data.google_status_details" :key="index">
            <div class="errors_cell">
              <ErrorSvg
                v-if="data.google_status === 'with_errors'"
                style="min-width: 16px; height: 16px"
              />
              <WarningSvg
                v-else-if="data.google_status === 'with_warnings'"
                style="min-width: 16px; height: 16px"
              />
              {{ item }}
            </div>
          </li>
        </ul>
      </template>
    </Column>

    <Column field="updated_at" header="Updated At" style="min-width: 190px">
      <template #body="{ data }">
        {{ helpers.formatTimeStamp(data.updated_at) }}
      </template>
    </Column>

    <template #empty>
      <empty-data></empty-data>
    </template>
    <template #loading> Loading customers data. Please wait. </template>
  </DataTable>
  <div class="deleteBar" v-if="selectedProducts.length > 0">
    <button @click="deleteSelected">Delete Selected</button>
    <button @click="selectedProducts = []" class="flat">Clear Selection</button>
    <p>{{ selectedProducts?.length }} selected</p>
  </div>
</template>

<script>
import EmptyData from "../GeneralComponents/EmptyData.vue";
import ErrorSvg from "./Icons/ErrorSvg.vue";
import WarningSvg from "./Icons/WarningSvg.vue";
import SubmittedSvg from "./Icons/SubmittedSvg.vue";
import RefreshSvg from "./Icons/RefreshSvg.vue";
import helpers from "@/services/helpers";
import MarketplaceSvg from "./Icons/MarketplaceSvg.vue";

export default {
  methods: {
    test() {
      alert("refresh page");
    },
    deleteSelected() {
      console.log(this.selectedProducts);
    },
  },
  props: ["productsData"],
  components: {
    EmptyData,
    ErrorSvg,
    WarningSvg,
    SubmittedSvg,
    RefreshSvg,
    MarketplaceSvg,
  },
  data() {
    return {
      helpers: helpers,
      selectedProducts: [],
    };
  },
};
</script>
<style scoped lang="scss">
.datatable {
  //Style table font
  :deep(.p-column-title),
  :deep(.p-datatable-tbody td) {
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #202223;
  }
  //Style table header and its font
  :deep(.p-datatable-thead > tr > th) {
    background-color: #fff;
    .p-column-title {
      font-weight: 500;
    }
  }
  // Style paginator and align it to the right
  :deep(div.p-paginator) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  :deep(button.p-paginator-prev),
  :deep(button.p-paginator-next),
  :deep(button.p-paginator-first),
  :deep(button.p-paginator-last) {
    color: #202223;
    width: 40px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
  }
  :deep(span.p-paginator-current) {
    cursor: default;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
  }
}
//Style of product column
.product_container {
  display: flex;
  gap: 22px;
}
.product_image {
  object-fit: cover;
  width: 55px;
  height: 55px;
}
.product_link:link,
.product_link:visited {
  color: #1575bf;
}
.product_link:hover {
  color: #00477e;
}
.product_link::before {
  content: "•";
  margin-right: 10px;
  text-decoration: none !important;
}
//Style of Errors Column
.errors_header {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}
.errors_cell {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
//Style the delete bar
.deleteBar {
  position: absolute;
  padding-left: 68px;
  top: 0;
  right: 0;
  width: 100%;
  height: 69px;
  display: flex;
  gap: 24px;
  align-items: center;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.deleteBar button {
  padding: 5.5px 21px;
  cursor: pointer;
  border: 1px solid #cecece;
  border-radius: 8px;
  outline: none;
  color: #c83e25;
}
.deleteBar button.flat {
  border: 1px solid #cecece;
  color: #fff;
  background-color: #c83e25;
}

.deleteBar button:hover {
  background-color: #f6f6f6;
}
.deleteBar button.flat:hover {
  background-color: #a12a18;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
